<template>
  <div>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <b-card>
      <b-row>
        <b-col cols="6" md="3" class="mb-1">
          <b-form-group label="Projects">
            <v-select v-model="projects" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="optionsProjects"
              label="" @option:selected="indexBanners" :loading="isLoadingProjects">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="3" md="3" align-self="center" class="d-flex">
          <b-button variant="primary" @click="showModalOpenGame(betprimeGame)">Launch</b-button>

        </b-col>
        <b-col cols="3" md="6" align-self="center" class="d-flex justify-content-end">
          <b-button v-if="projects != ''" variant="primary" @click="isModal = !isModal">New banners</b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs content-class="mt-3">
        <b-tab title="Banners" active>
          <div>
            <b-row class="d-flex justify-content-center">
              <b-col cols="12" md="12">
                <carruselVue ref="carruselVueRef" :banners="banners" :wl="projects" />
              </b-col>
              <b-col v-if="!projects" cols="12" md="12" class="mt-2 text-center">
                <h2>Select a project</h2>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-modal v-model="isModal" title="New Banners" size="lg" centered hide-footer modal-class="modal-primary">
              <b-overlay :show="loadingData" variant="transparent" no-wrap />
              <form @submit.prevent="saveBanners">
                <b-row>
                  <b-col cols="12" md="12" class="mb-1">
                    <b-overlay :show="loadingUploadImage" opacity="0.6">
                      <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                          <b-spinner small type="grow" variant="secondary" />
                          <b-spinner type="grow" variant="dark" />
                          <b-spinner small type="grow" variant="secondary" />
                          <!-- We add an SR only text for screen readers -->
                          <span class="sr-only">Please wait...</span>
                        </div>
                      </template>
                      <b-input-group invalid-feedback="Img is required">
                        <b-input-group-prepend>
                          <b-button :loading="!loadingUploadImage" variant="primary"
                            @click="$refs.refInputEl.$el.click()">
                            <feather-icon icon="UploadIcon" />
                          </b-button>
                        </b-input-group-prepend>
                        <b-form-input label="Image URL" v-model="bannerForm.image" required @input="saveBanners" />
                      </b-input-group>
                    </b-overlay>

                    <b-form-file ref="refInputEl" v-model="imageUpload" accept=".jpg, .png, .gif, .jpeg, .svg"
                      :hidden="true" plain @input="uploadImage" />
                  </b-col>

                  <b-col cols="12" md="6" class="mb-1">
                    <b-form-group label="Title">
                      <b-form-input v-model="bannerForm.title"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6" class="mb-1">
                    <b-form-group label="Description">
                      <b-form-input v-model="bannerForm.description"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6" class="mb-1">
                    <b-form-group label="Link">
                      <b-form-input v-model="bannerForm.link"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6" class="mb-1">
                    <b-form-group label="Platform">
                      <v-select v-model="bannerForm.platform" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="platform" label="text" @reduce="(option) => option.value">
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6" class="mb-1">
                    <b-form-group label="Start date">
                      <flat-pickr v-model="bannerForm.startDate" class="form-control" :config="{
                        altInput: true,
                        altFormat: 'd-m-Y H:i',
                        enableTime: true,
                        time_24hr: true,
                      }" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6" class="mb-1">
                    <b-form-group label="End date">
                      <flat-pickr v-model="bannerForm.endDate" class="form-control" :config="{
                        altInput: true,
                        altFormat: 'd-m-Y H:i',
                        enableTime: true,
                        time_24hr: true,
                      }" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6" class="d-flex justify-content-between align-items-start mb-1">
                    <b-form-group label="Buttons">
                      <b-toast v-model="isToastButtoms" title="Add Buttoms" static no-auto-hide class="mt-1">
                        <form>
                          <b-form-group label="Text">
                            <b-form-input v-model="buttoms.text"></b-form-input>
                          </b-form-group>

                          <b-form-group label="Link">
                            <b-form-input v-model="buttoms.link"></b-form-input>
                          </b-form-group>

                          <b-form-group label="Background">
                            <input type="color" class="form-control" @blur="changeColor('background', $event)"
                              :value="buttoms.background" />
                          </b-form-group>

                          <b-form-group label="Text color">
                            <input type="color" class="form-control" @blur="changeColor('color', $event)"
                              :value="buttoms.color" />
                          </b-form-group>

                          <div class="d-flex justify-content-center mt-1">
                            <button :style="{
                              backgroundColor: buttoms.background,
                              color: buttoms.color,
                            }" class="btn btn-sm text-nowrap">
                              {{ buttoms.text }}
                            </button>
                          </div>
                          <div class="d-flex justify-content-between mt-1">
                            <b-col cols="4">
                              <b-button v-if="buttomIndex !== -1" variant="danger" size="sm" @click="deleteButton">
                                <feather-icon :icon="'TrashIcon'" />
                              </b-button>
                            </b-col>
                            <b-col cols="6" class="d-flex">
                              <b-button size="sm" class="mr-1" variant="primary" @click="addEditButton()">
                                Save
                              </b-button>
                              <b-button size="sm" @click="closeToastBottoms"> Cancel </b-button>
                            </b-col>
                          </div>
                        </form>
                      </b-toast>

                      <div v-for="(button, i) in bannerForm.buttons" :key="i" class="d-flex">
                        <button :style="{
                          backgroundColor: button.background,
                          color: button.color,
                        }" class="btn btn-sm mb-1 text-nowrap" @click="showToast(button, i)">
                          {{ button.text }}
                        </button>
                      </div>
                    </b-form-group>
                    <b-button class="mb-2 m" size="sm" variant="success" @click="isToastButtoms = !isToastButtoms">
                      <feather-icon :icon="'PlusIcon'" />
                    </b-button>
                  </b-col>

                  <b-col cols="12" md="6" class="mb-1">
                    <b-form-group label="Position" class="d-flex justify-content-center">
                      <div>
                        <b-col class="d-flex">
                          <b-button :variant="bannerForm.position == 'left top' ? 'primary' : 'outline-primary'
                            " size="sm" class="mr-1 mb-1" @click="selectPosition('left top')">
                            <feather-icon :icon="'ArrowUpLeftIcon'" size="1.5x" />
                          </b-button>
                          <b-button :variant="bannerForm.position == 'top center'
                            ? 'primary'
                            : 'outline-primary'
                            " size="sm" class="mb-1" @click="selectPosition('top center')">
                            <feather-icon :icon="'ArrowUpIcon'" size="1.5x" />
                          </b-button>
                          <b-button :variant="bannerForm.position == 'right top' ? 'primary' : 'outline-primary'
                            " size="sm" class="ml-1 mb-1" @click="selectPosition('right top')">
                            <feather-icon :icon="'ArrowUpRightIcon'" size="1.5x" />
                          </b-button>
                        </b-col>
                        <b-col class="d-flex">
                          <b-button :variant="bannerForm.position == 'left center'
                            ? 'primary'
                            : 'outline-primary'
                            " size="sm" class="mr-1 mb-1" @click="selectPosition('left center')">
                            <feather-icon :icon="'ArrowLeftIcon'" size="1.5x" />
                          </b-button>
                          <b-button :variant="bannerForm.position == 'center center'
                            ? 'primary'
                            : 'outline-primary'
                            " size="sm" class="mb-1" @click="selectPosition('center center')">
                            <feather-icon :icon="'DiscIcon'" size="1.5x" />
                          </b-button>
                          <b-button :variant="bannerForm.position == 'right center'
                            ? 'primary'
                            : 'outline-primary'
                            " size="sm" class="ml-1 mb-1" @click="selectPosition('right center')">
                            <feather-icon :icon="'ArrowRightIcon'" size="1.5x" />
                          </b-button>
                        </b-col>
                        <b-col class="d-flex">
                          <b-button :variant="bannerForm.position == 'left bottom'
                            ? 'primary'
                            : 'outline-primary'
                            " size="sm" class="mr-1 mb-1" @click="selectPosition('left bottom')">
                            <feather-icon :icon="'ArrowDownLeftIcon'" size="1.5x" />
                          </b-button>
                          <b-button :variant="bannerForm.position == 'bottom center'
                            ? 'primary'
                            : 'outline-primary'
                            " size="sm" class="mb-1" @click="selectPosition('bottom center')">
                            <feather-icon :icon="'ArrowDownIcon'" size="1.5x" />
                          </b-button>
                          <b-button :variant="bannerForm.position == 'right bottom'
                            ? 'primary'
                            : 'outline-primary'
                            " size="sm" class="ml-1 mb-1" @click="selectPosition('right bottom')">
                            <feather-icon :icon="'ArrowDownRightIcon'" size="1.5x" />
                          </b-button>
                        </b-col>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="d-flex justify-content-end mt-2">
                  <b-button :variant="'success'" type="submit">
                    <feather-icon :icon="'PlusIcon'" class="mr-50" />
                    Add
                  </b-button>
                </div>
              </form>
            </b-modal>
          </div>
        </b-tab>
        <b-tab title="Estilos">
          <projectStylesVue :product="projects" />
        </b-tab>

      </b-tabs>
    </b-card>
    <OpenGame ref="openGame" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
  BOverlay,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  VBModal,
  VBToggle,
  BCarousel,
  BCarouselSlide,
  BToast,
  BFormFile,
  BInputGroupPrepend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import carruselVue from "./carrusel.vue";
import projectStylesVue from "./projectStyles.vue";
import modalAddEditBannersVue from "./modalAddEditBanners.vue";
import OpenGame from "../games/openGame.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BTabs,
    BTab,
    BFormSelect,
    BFormGroup,
    OpenGame,
    BFormInput,
    BButton,
    BInputGroup,
    VBModal,
    vSelect,
    ToastificationContent,
    VBToggle,
    BCarousel,
    BCarouselSlide,
    carruselVue,
    projectStylesVue,
    modalAddEditBannersVue,
    BToast,
    flatPickr,
    BFormFile,
    BInputGroupPrepend,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  // add filters

  data() {
    return {
      imageUpload: null,
      loadingUploadImage: false,
      isModal: false,
      isToastButtoms: false,
      isLoadingProjects: false,
      loadingData: false,
      projects: "",
      buttomIndex: -1,
      optionsProjects: [],
      buttoms: {
        text: "",
        link: "",
        background: "#0078C4",
        color: "#FFFFFF",
      },
      betprimeGame: {
        "_id": "66d7acb9ea8d10d618bcf7c6",
        "gameId": "betprime",
        "game_id": null,
        "codeId": null,
        "gameName": "Bet Prime",
        "brandId": {
          "_id": "66d7aca694045213ec0d0e3e",
          "brandId": "betprime",
          "name": "betprime",
          "description": "Bet Prime",
          "status": true,
          "softDelete": null,
          "createdAt": "2024-09-04T00:41:10.581Z",
          "updatedAt": "2024-09-06T23:43:05.756Z",
          "__v": 0,
          "url_image": "https://images.kbapi.net/images/LogoMarcas/betprime.jpg"
        },
        "categories": [],
        "brandName": "betprime",
        "url_image": "null",
        "status": true,
        "product": {
          "_id": "66d7ac2494045213ec0d0e04",
          "category": "655268d2a16fe0e0186322a6",
          "productId": "betprime",
          "brands": [],
          "name": "betprime",
          "description": "Bet Prime",
          "uploadType": 0,
          "credentials": {
            "project": "78430",
            "wheel": false,
            "server": "https://sport.betcoreapps.com",
            "cdn": "https://cdn.betcoreapps.com",
            "logo": "https://images.kbapi.net/images/Apuestas%20Deportivas%20logo.png",
            "storageName": "betcore_storage",
            "type": "line",
            "linkPrefix": "/sport",
            "ancho": 300,
            "alto": 300,
            "pages": {
              "winss": {
                "project": "78431"
              },
              "zonebet": {
                "project": "78432"
              }
            },
            "projects": this.projects
          },
          "isgame": false,
          "ispayment": false,
          "status": true,
          "gamesTotal": 0,
          "softDelete": null,
          "restrictedCurrencies": [],
          "createdAt": "2024-09-04T00:39:00.524Z",
          "updatedAt": "2024-10-03T14:57:56.905Z",
          "__v": 0,
          "url_image": "https://staging.integrationscenter.net/public/images/noimageavailable.png",
          "isImportGamesApi": null,
          "isImportGamesArchive": null
        },
        "isMobile": false,
        "isDesktop": true,
        "tags": [
          "betprime"
        ],
        "tagsId": [],
        "positionByTag": [],
        "currencies": [],
        "softDelete": null,
        "createdAt": "2024-09-04T00:41:29.281Z",
        "updatedAt": "2024-09-04T00:41:29.281Z",
        "__v": 0,
        "vgt_id": 0,
        "originalIndex": 0
      },
      platform: [
        { value: 0, text: "All" },
        { value: 1, text: "Desktop" },
        { value: 2, text: "Mobile" },
      ],
      banners: [],
      bannerForm: {
        title: "",
        description: "",
        projects: [],
        buttons: [],
        link: "",
        position: "left center",
        image: "",
        startDate: "",
        endDate: "",
        platform: 0,
      },
    };
  },
  computed: {},
  methods: {
    showModalOpenGame(game) {
      this.$refs.openGame.openModal = true
      this.$refs.openGame.resGame = game
      console.log("PROJECTS", this.projects)
      this.$refs.openGame.individualCred = this.projects;
      this.$refs.openGame.dataCustomer()
      this.$refs.openGame.resetModalData()
    },
    async uploadImage(fileData) {
      if (!fileData) {
        console.error("No se ha seleccionado ningún archivo.");
        return;
      }
      try {
        this.loadingUploadImage = true;
        const response = await this.$store.dispatch("filesModule/uploadFile", { fileData, path: "banners" });
        this.bannerForm.image = response.data.url;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckIcon",
            variant: "success",
            text: "Image uploaded",
          },
        });
        this.imageUpload = null;
      } catch (error) {
        console.error("Error subiendo la imagen:", error);
      } finally {
        this.loadingUploadImage = false;
      }
    },
    async projectsBanners() {
      try {
        this.isLoadingProjects = true;
        this.optionsProjects = await this.$store.dispatch(
          "betcoreBanners/getProjectsBanners"
        );

        this.isLoadingProjects = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingProjects = false;
      }
    },
    async indexBanners(select) {
      try {
        this.loadingData = true;
        /*  this.banners = []; */
        this.$refs.carruselVueRef.localBanners = [];
        let res = await this.$store.dispatch("betcoreBanners/getBetcoreBanners", select);
        if (!Array.isArray(res.data)) {
          return;
        }
        this.banners = res.data;
        this.$refs.carruselVueRef.localBanners = this.banners || [];
        this.$refs.carruselVueRef.bannerForm = this.banners?.[0];
        this.loadingData = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
      }
    },
    showToast(buttom, i) {
      this.isToastButtoms = true;
      this.buttoms = {
        ...buttom,
      };
      this.buttomIndex = i;
    },
    isURL(cadena, type) {
      try {
        new URL(cadena);
        return true;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "XCircleIcon",
            variant: "danger",
            text: `${type}: URL is not valid`,
          },
        });
        return false;
      }
    },
    addEditButton() {
      const buttonIndex = this.bannerForm.buttons.findIndex(
        (btn) => btn.text === this.buttoms.text
      );
      if (buttonIndex !== -1) {
        this.bannerForm.buttons[buttonIndex] = this.buttoms;
      } else {
        this.bannerForm.buttons.push({
          ...this.buttoms,
        });
      }
      this.closeToastBottoms();
    },
    deleteButton() {
      this.bannerForm.buttons.splice(this.buttomIndex, 1);
      this.saveBanners();
      this.closeToastBottoms();
    },
    closeToastBottoms() {
      (this.buttoms = {
        text: "",
        link: "",
        background: "#0078C4",
        color: "#FFFFFF",
      }),
        (this.buttomIndex = -1);
      this.isToastButtoms = false;
    },
    async saveBanners() {
      try {
        if (!this.isURL(this.bannerForm.image, "image")) return;
        this.bannerForm.projects[0] = this.projects;
        this.loadingData = true;
        this.banners.push(this.bannerForm);
        const payload = {
          data: this.banners,
        };
        let wl = this.projects;
        this.banners = await this.$store.dispatch("betcoreBanners/betcoreBanners", {
          payload,
          wl,
        });
        this.isModal = false;
        this.bannerForm = {
          title: "",
          description: "",
          buttons: [],
          link: "",
          position: "left center",
          image: "",
          platform: 0,
        };
        this.indexBanners(wl);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckCircleIcon",
            variant: "success",
            text: "Banners created",
          },
        });
        this.loadingData = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
      }
    },
    selectPosition(position) {
      this.bannerForm.position = position;
    },
  },
  mounted() {
    this.projectsBanners();
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
