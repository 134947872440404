<template>
    <div>
        <b-overlay :show="loadingData" variant="transparent" no-wrap />
        <b-col v-if="product" cols="12" md="12" lg="12">

            <b-row v-for="items in cssJson" :key="items.name" class="mb-1">
                <b-col cols="12" md="6" class="text-right">
                    <label>{{ items.name }}</label>
                </b-col>
                <b-col cols="12" md="6">
                    <!-- Input de color (actualiza directamente el valor) -->
                    <b-form-input v-if="items.type === 'Hex' || items.type === 'RGBA'" v-model="items.value"
                        @input="updateCssString" type="color" placeholder="Value" class="d-inline-block"
                        style="width: 30px; margin-right: 10px; height: 25px;">
                    </b-form-input>
                    <b-form-input v-if="items.type === 'Hex' || items.type === 'RGBA'" v-model="items.value"
                        @input="updateCssString" type="text" placeholder="Value" class="d-inline-block"
                        style="width: calc(100% - 40px); font-size: 13px; height: 25px;">
                    </b-form-input>
                    <b-form-input v-if="items.type === 'Size' || items.type === 'Number' || items.type === 'Text'"
                        v-model="items.value" @input="updateCssString" type="text"
                        style="height: 25px; font-size: 13px;" placeholder="Value">
                    </b-form-input>
                    <template v-if="parsedGradients[items.name]">
                        <b-row>
                            <b-col cols="12" md="4" lg="4" sm="4" class="text-left">
                                <b-form-input v-for="(color, index) in parsedGradients[items.name].colors" :key="index"
                                    v-model="parsedGradients[items.name].colors[index]" @input="updateCssString"
                                    type="color" class="d-inline-block"
                                    style="width: 30px; margin-right: 10px; height: 25px;">
                                </b-form-input>
                            </b-col>
                            <b-col cols="12" md="6" lg="6" sm="6" class="text-left">
                                <b-form-input v-model="items.value"
                                    :value="constructGradient(parsedGradients[items.name].angleOrDirection, parsedGradients[items.name].colors)"
                                    @input="updateCssString" type="text" class="d-inline-block"
                                    style="width: 100%; height: 25px;">
                                </b-form-input>
                            </b-col>
                            <b-col cols="12" md="2" lg="2" sm="2" class="text-left">
                                <div :style="{
                                    backgroundImage: constructGradient(parsedGradients[items.name].angleOrDirection, parsedGradients[items.name].colors),
                                    width: '100%', height: '25px', borderRadius: '4px', border: '1px solid #ccc'
                                }">
                                </div>
                            </b-col>
                        </b-row>
                    </template>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="12" class="mb-1">
                    <div class="d-flex justify-content-end mt-2">
                        <b-button v-if="product" :variant="'success'" @click="save">
                            <feather-icon :icon="'SaveIcon'" class="mr-50" />
                            Guardar
                        </b-button>
                    </div>
                </b-col>
            </b-row>

        </b-col>
        <div class="text-center" v-else="!product">
            <h2>Select a project</h2>
        </div>
    </div>


</template>

<script>
import { BRow, BCol, BFormInput, BCard, BButton, BOverlay } from "bootstrap-vue";
import { backgroundColor } from "echarts/lib/theme/dark";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BRow,
        BCol,
        BOverlay,
        BButton,
        ToastificationContent,
        BFormInput,
        BCard,
    },
    props: {
        product: ''
    },
    data() {
        return {
            cssString: '',
            loadingData: false,
            cssJson: [],
            parsedGradients: {}
        };
    },
    watch: {
        cssJson: {
            handler(newJson) {
                newJson.forEach(item => {
                    if (item.type === 'Gradient') {
                        const parsed = this.parseGradient(item.value);
                        if (parsed) {
                            // Asegúrate de que cada gradiente se inicializa correctamente
                            this.$set(this.parsedGradients, item.name, parsed);
                        }
                    }
                });
            },
            deep: true, // Observar cambios profundos en los objetos
            immediate: true // Ejecutar inmediatamente al montar el componente
        },
        product: {
            handler(value) {
                this.getStyles();
            },
            deep: true,
        }
    },
    methods: {
        updateCssString() {
            let newCssString = '';
            this.cssJson.forEach(item => {
                // Si es un gradiente, reconstruimos el gradiente completo
                if (item.type === 'Gradient') {
                    const gradient = this.constructGradient(this.parsedGradients[item.name].angleOrDirection, this.parsedGradients[item.name].colors);
                    newCssString += `${item.name}: ${gradient};\n`;
                } else {
                    // Para otros tipos, usamos el valor directamente
                    newCssString += `${item.name}: ${item.value};\n`;
                }
            });
            this.cssString = newCssString;
        },
        addNewlinesIfMissing(styleString) {
            // This function adds a newline after each semicolon if it doesn't have one
            return styleString.replace(/;(?!\n)/g, ';\n');
        },
        parseCssVariables() {
            const hexColorPattern = /#[A-Fa-f0-9]{3,6}/;
            const sizePattern = /(\d+px|\d+vh|\d+%)/;
            const rgbaPattern = /rgba?\([^\)]+\)/;
            const gradientPattern = /linear-gradient\([^)]*\)/;
            const numberPattern = /^\d+(\.\d+)?$/;

            this.cssString.split('\n').forEach(line => {
                if (line.includes(':')) {
                    let [name, value] = line.split(':');
                    name = name.trim();
                    value = value.trim().replace(';', '');


                    let valueType = "text";
                    if (gradientPattern.test(value)) {
                        valueType = "Gradient";
                    } else if (hexColorPattern.test(value)) {
                        valueType = "Hex";
                    } else if (rgbaPattern.test(value)) {
                        valueType = "RGBA";
                    } else if (sizePattern.test(value)) {
                        valueType = "Size";
                    } else if (numberPattern.test(value)) {
                        valueType = "Number";
                    }
                    this.cssJson.push({
                        name: name,
                        value: value,
                        type: valueType
                    });
                }
            });
            return this.cssJson;
        },
        async getStyles() {
            this.cssJson = [];
            let res = await this.$store.dispatch("betcoreBanners/getBetcoreStyles", {
                projectId: this.product,
            });
            this.cssString = this.addNewlinesIfMissing(res.style);
            this.parseCssVariables();
        },
        parseGradient(gradientValue) {
            // Aquí va la función parseGradient corregida
            const gradientParts = gradientValue.match(/linear-gradient\(([^,]+),(.+)\)/);

            if (gradientParts && gradientParts.length === 3) {
                const angleOrDirection = gradientParts[1].trim(); // Captura el ángulo o dirección
                const colors = gradientParts[2].split(',').map(color => color.trim()); // Captura los colores

                return {
                    angleOrDirection,
                    colors
                };
            }

            return null;
        },
        constructGradient(angleOrDirection, colors) {
         
            if (!angleOrDirection || !colors || colors.length === 0) {
                return ''; 
            }
            return `linear-gradient(${angleOrDirection}, ${colors.join(', ')})`;
        },
        async save() {
            this.loadingData = true;
            this.cssString = this.cssString.replace(/\n/g, ' ');
            const lastCharacter = this.cssString.charAt(this.cssString.length - 1);
            if (lastCharacter !== '}') {
                this.cssString += '}';
            }
                console.log(this.cssString)

                const params = {
                    projectId: this.product,
                    style: this.cssString,
                }
                let res = await this.$store.dispatch("betcoreBanners/saveBetcoreStyles", params);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Success",
                        icon: "CheckIcon",
                        variant: "success",
                        text: "Styles updated",
                    },
                });
                this.loadingData = false;
            }
        },
        mounted() {

            this.parseCssVariables();
        },
    };
</script>

<style lang="scss"></style>