<template>
  <div>
    <b-overlay :show="loadingData" variant="transparent" no-wrap />
    <div v-if="showBanners && wl !== null">
      <b-row>
        <b-col cols="12" md="12" lg="7" class="d-flex justify-content-center">
          <b-col cols="12" :md="isMovil ? '7' : '12'">
            <div class="mb-1 d-flex justify-content-center">
              <b-button id="button-1" :variant="isAll ? 'primary' : 'outline-primary'" size="sm" class="mr-1"
                @click="BannerPlatform('isAll')">
                <feather-icon :icon="'GlobeIcon'" />
              </b-button>
              <b-button id="button-2" :variant="isMovil ? 'primary' : 'outline-primary'" size="sm" class="mr-1"
                @click="BannerPlatform('isMovil')">
                <feather-icon :icon="'SmartphoneIcon'" />
              </b-button>
              <b-button id="button-3" :variant="isDesktop ? 'primary' : 'outline-primary'" size="sm"
                @click="BannerPlatform('isDesktop')">
                <feather-icon :icon="'MonitorIcon'" />
              </b-button>
              <b-tooltip target="button-1" title="All" triggers="hover" />
              <b-tooltip target="button-2" title="Mobile" triggers="hover" />
              <b-tooltip target="button-3" title="Desktop" triggers="hover" />
            </div>
            <div id="carouselExample" class="carousel slide">
              <ol class="carousel-indicators">
                <li v-for="(image, index) in localBanners" :key="index" :class="{ active: index === activeIndex }"
                  @click="goToSlide(index)"></li>
              </ol>
              <div class="carousel-inner">
                <div v-for="(banner, index) in localBanners" :key="index"
                  :class="['carousel-item', { active: index === activeIndex }]">
                  <img :src="banner.image" class="w-100" :height="isMovil ? '200' : '300'" alt="Img"
                    @error="handleImageError($event)" />
                  <b-col cols="3" class="carousel-caption" :class="setCaptionClass(banner.position)">
                    <h4 class="text-white text-nowrap">{{ banner.title }}</h4>
                    <p class="text-white text-nowrap">
                      {{ banner.description }}
                    </p>
                    <div class="d-flex">
                      <button v-for="(button, i) in banner.buttons" :key="i" :style="{
                        backgroundColor: button.background,
                        color: button.color,
                      }" @click="button.onclick ? button.onclick() : goToLink(button.link)"
                        class="btn btn-sm ml-1 text-nowrap">
                        {{ button.text }}
                      </button>
                    </div>
                  </b-col>
                </div>
              </div>
              <a class="carousel-control-prev" style="margin-left: -10px !important" href="#" role="button"
                @click.prevent="prevSlide">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" style="margin-right: -10px !important" href="#" role="button"
                @click.prevent="nextSlide">
                <span class="carousel-control-next-icon tex-" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>

            <draggable v-model="localBanners" tag="ul" class="cursor-move d-flex justify-content-center mt-2">
              <img v-for="(item, index) in localBanners" :src="item.image" :key="index" height="70" width="80"
                class="mr-1 img-thumbnail" @click="goToSlide(index)" />
            </draggable>
          </b-col>
        </b-col>

        <b-col cols="12" md="12" lg="5">
          <b-card>
            <b-row>
              <b-col cols="12" md="12" class="mb-1">
                <b-overlay :show="loadingUploadImage" opacity="0.6">
                  <template v-slot:overlay>
                    <div class="d-flex align-items-center">
                      <b-spinner small type="grow" variant="secondary" />
                      <b-spinner type="grow" variant="dark" />
                      <b-spinner small type="grow" variant="secondary" />
                      <!-- We add an SR only text for screen readers -->
                      <span class="sr-only">Please wait...</span>
                    </div>
                  </template>
                  <b-input-group invalid-feedback="Img is required">
                    <b-input-group-prepend>
                      <b-button :loading="!loadingUploadImage" variant="primary" @click="$refs.refInputEl.$el.click()">
                        <feather-icon icon="UploadIcon" />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input label="Image URL" v-model="bannerForm.image" required @input="saveBanners" />
                  </b-input-group>
                </b-overlay>

                <b-form-file ref="refInputEl" v-model="imageUpload" accept=".jpg, .png, .gif, .jpeg, .svg"
                  :hidden="true" plain @input="uploadImage" />
              </b-col>

              <b-col cols="12" md="6" class="mb-1">
                <b-form-group label="Title" invalid-feedback="Title is required">
                  <b-form-input v-model="bannerForm.title" required @input="saveBanners"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" class="mb-1">
                <b-form-group label="Description">
                  <b-form-input v-model="bannerForm.description" @input="saveBanners"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" class="mb-1">
                <b-form-group label="Link">
                  <b-form-input v-model="bannerForm.link" @input="saveBanners"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" class="mb-1">
                <b-form-group label="Platform">
                  <v-select v-model="bannerForm.platform" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="platform" label="text" :reduce="(platform) => platform.value" @input="saveBanners">
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" class="mb-1">
                <b-form-group label="Start date">
                  <flat-pickr v-model="bannerForm.startDate" class="form-control" :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                  }" @input="saveBanners" />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" class="mb-1">
                <b-form-group label="End date">
                  <flat-pickr v-model="bannerForm.endDate" class="form-control" :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                  }" @input="saveBanners" />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="12">
                <b-form-group label="Position" class="d-flex justify-content-center">
                  <div>
                    <b-col class="d-flex">
                      <b-button :variant="bannerForm.position == 'left top'
                        ? 'primary'
                        : 'outline-primary'
                        " size="sm" class="mr-1 mb-1" @click="selectPosition('left top')">
                        <feather-icon :icon="'ArrowUpLeftIcon'" size="1.5x" />
                      </b-button>
                      <b-button :variant="bannerForm.position == 'top center'
                        ? 'primary'
                        : 'outline-primary'
                        " size="sm" class="mb-1" @click="selectPosition('top center')">
                        <feather-icon :icon="'ArrowUpIcon'" size="1.5x" />
                      </b-button>
                      <b-button :variant="bannerForm.position == 'right top'
                        ? 'primary'
                        : 'outline-primary'
                        " size="sm" class="ml-1 mb-1" @click="selectPosition('right top')">
                        <feather-icon :icon="'ArrowUpRightIcon'" size="1.5x" />
                      </b-button>
                    </b-col>
                    <b-col class="d-flex">
                      <b-button :variant="bannerForm.position == 'left center'
                        ? 'primary'
                        : 'outline-primary'
                        " size="sm" class="mr-1 mb-1" @click="selectPosition('left center')">
                        <feather-icon :icon="'ArrowLeftIcon'" size="1.5x" />
                      </b-button>
                      <b-button :variant="bannerForm.position == 'center center'
                        ? 'primary'
                        : 'outline-primary'
                        " size="sm" class="mb-1" @click="selectPosition('center center')">
                        <feather-icon :icon="'DiscIcon'" size="1.5x" />
                      </b-button>
                      <b-button :variant="bannerForm.position == 'right center'
                        ? 'primary'
                        : 'outline-primary'
                        " size="sm" class="ml-1 mb-1" @click="selectPosition('right center')">
                        <feather-icon :icon="'ArrowRightIcon'" size="1.5x" />
                      </b-button>
                    </b-col>
                    <b-col class="d-flex">
                      <b-button :variant="bannerForm.position == 'left bottom'
                        ? 'primary'
                        : 'outline-primary'
                        " size="sm" class="mr-1 mb-1" @click="selectPosition('left bottom')">
                        <feather-icon :icon="'ArrowDownLeftIcon'" size="1.5x" />
                      </b-button>
                      <b-button :variant="bannerForm.position == 'bottom center'
                        ? 'primary'
                        : 'outline-primary'
                        " size="sm" class="mb-1" @click="selectPosition('bottom center')">
                        <feather-icon :icon="'ArrowDownIcon'" size="1.5x" />
                      </b-button>
                      <b-button :variant="bannerForm.position == 'right bottom'
                        ? 'primary'
                        : 'outline-primary'
                        " size="sm" class="ml-1 mb-1" @click="selectPosition('right bottom')">
                        <feather-icon :icon="'ArrowDownRightIcon'" size="1.5x" />
                      </b-button>
                    </b-col>
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="12" class="d-flex justify-content-start align-items-start">
                <b-col cols="11">
                  <b-form-group label="Buttons">
                    <b-toast v-model="isToastButtoms" title="Add Buttoms" static no-auto-hide class="mt-1">
                      <b-form-group label="Text">
                        <b-form-input v-model="buttoms.text"></b-form-input>
                      </b-form-group>

                      <b-form-group label="Link">
                        <b-form-input v-model="buttoms.link"></b-form-input>
                      </b-form-group>

                      <b-form-group label="Background">
                        <input type="color" class="form-control" @blur="changeColor('background', $event)"
                          :value="buttoms.background" />
                      </b-form-group>

                      <b-form-group label="Text color">
                        <input type="color" class="form-control" @blur="changeColor('color', $event)"
                          :value="buttoms.color" />
                      </b-form-group>

                      <div class="d-flex justify-content-center mt-1">
                        <button :style="{
                          backgroundColor: buttoms.background,
                          color: buttoms.color,
                        }" class="btn btn-sm text-nowrap">
                          {{ buttoms.text }}
                        </button>
                      </div>
                      <div class="d-flex justify-content-between mt-1">
                        <b-col cols="4">
                          <b-button v-if="buttomIndex !== -1" variant="danger" size="sm" @click="deleteButton">
                            <feather-icon :icon="'TrashIcon'" />
                          </b-button>
                        </b-col>
                        <b-col cols="6" class="d-flex">
                          <b-button size="sm" class="mr-1" variant="primary" @click="addEditButton()">
                            Save
                          </b-button>
                          <b-button size="sm" @click="closeToastBottoms">
                            Cancel
                          </b-button>
                        </b-col>
                      </div>
                    </b-toast>

                    <div v-for="(button, i) in bannerForm.buttons" :key="i" class="d-flex">
                      <button :style="{
                        backgroundColor: button.background,
                        color: button.color,
                      }" class="btn btn-sm mb-1 text-nowrap" @click="showToast(button, i)">
                        {{ button.text }}
                      </button>
                    </div>
                  </b-form-group>

                  <b-form-group>
                    <label class="mr-1 text-lg" for="customRadioInline1">Projects</label>
                    <v-select v-model="projects" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="optionsProjects" label="name" @option:selected="selectCustomer"
                      :reduce="(customer) => customer" value="" multiple
                      :loading="loadingDataCustomer">
                      <template #option="{ name }">
                        <feather-icon icon="UserIcon" size="16" class="align-middle mr-50" />
                        <span> {{ name }}</span>
                      </template>

                      <!-- buttom select all  -->
                      <template #list-header>
                        <div class="d-flex justify-content-center my-1">
                          <b-button size="sm" @click="projects = optionsProjects"
                            class="text-nowrap" variant="primary" v-if="
                              optionsProjects.length > 1 &&
                              projects.length !== optionsProjects.length">
                            <feather-icon icon="CheckSquareIcon" class="mr-50" />
                            <span class="text-nowrap">Select All</span>
                          </b-button>
                          <b-button size="sm" @click="projects = []" class="text-nowrap" variant="primary"
                            v-if="projects.length > 0">
                            <feather-icon icon="SquareIcon" class="mr-50" />
                            <span class="text-nowrap">Unselect All</span>
                          </b-button>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>


                <b-button class="mb-2" size="sm" variant="success" @click="isToastButtoms = !isToastButtoms">
                  <feather-icon :icon="'PlusIcon'" />
                </b-button>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-between mt-2">
              <b-button variant="danger" @click="deleteBanner">
                <feather-icon :icon="'TrashIcon'" class="mr-50" />
                Delete
              </b-button>
              <b-button variant="success" @click="putBanners">
                <feather-icon :icon="'PlusIcon'" class="mr-50" />
                Save
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="text-center" v-if="!showBanners && wl !== ''">
      <h2>There are no banners</h2>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  VBModal,
  VBToggle,
  BCarousel,
  BCarouselSlide,
  BToast,
  BTooltip,
  BListGroupItem,
  BFormFile,
  BInputGroupPrepend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import draggable from "vuedraggable";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    VBModal,
    vSelect,
    ToastificationContent,
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BToast,
    BTooltip,
    BListGroupItem,
    draggable,
    flatPickr,
    BFormFile,
    BInputGroupPrepend,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  // add filters
  props: {
    banners: {
      type: Array,
      required: true,
    },
    wl: {
      type: String,
      required: true,
    },
  },
  watch: {
    "projects": {

      handler(value) {
        this.localBanners[this.activeIndex].projects = value;
      },
      deep: true,
    },
    localBanners: {
      handler(value) {        
          this.autoFillProjects();
      },
      deep: true,
    },
  },
  data() {
    return {
      loadingUploadImage: false,
      activeIndex: 0,
      buttomIndex: -1,
      timeout: null,
      projects: [],
      showBanners: false,
      optionsProjects: [],
      loadingDataCustomer: false,
      imageUpload: null,
      buttoms: {
        text: "",
        link: "",
        background: "#0078C4",
        color: "#FFFFFF",
      },
      filterSelect: {
        customer: [],
      },
      customer: [],
      platform: [
        { value: 0, text: "All" },
        { value: 1, text: "Desktop" },
        { value: 2, text: "Mobile" },
      ],
      bannerForm: {
        image: "",
        title: "",
        description: "",
        link: "",
        projects: [],
        platform: "",
        startDate: "",
        endDate: "",
        position: "left top",
        buttons: [],
      },
      localBanners: this.banners,
      isToastButtoms: false,
      isMovil: false,
      isDesktop: false,
      isAll: true,
      loadingData: false,
      imgError: "https://via.placeholder.com/150?text=Image+Error",
    };
  },
  computed: {
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
  },
  mounted() {
    this.projectsBanners();

    window.addEventListener("resize", this.updateTransform);
    /* this.autoFillProjects(); */
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateTransform);
  },
  methods: {
    async projectsBanners() {
      try {
        this.isLoadingProjects = true;
        this.optionsProjects = await this.$store.dispatch(
          "betcoreBanners/getProjectsBanners"
        );
        this.isLoadingProjects = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingProjects = false;
      }
    },
    async uploadImage(fileData) {
      if (!fileData) {
        console.error("No se ha seleccionado ningún archivo.");
        return;
      }
      try {
        this.loadingUploadImage = true;
        const response = await this.$store.dispatch("filesModule/uploadFile", { fileData, path: "banners" });
        this.bannerForm.image = response.data.url;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckIcon",
            variant: "success",
            text: "Image uploaded",
          },
        });
        this.imageUpload = null;
        this.saveBanners();
        this.putBanners();
      } catch (error) {
        console.error("Error subiendo la imagen:", error);
      } finally {
        this.loadingUploadImage = false;
      }
    },
    changeColor(type, event) {
      this.buttoms[type] = event.target.value;
    },
    handleImageError(event) {
      event.target.src = this.imgError;
    },
    updateTransform() {
      this.$forceUpdate();
    },

    goToSlide(index) {
      this.activeIndex = index;
      this.editBanner(index);
      this.projects =  this.localBanners[this.activeIndex].projects
    },
    prevSlide() {
      this.activeIndex =
        this.activeIndex === 0 ? this.localBanners.length - 1 : this.activeIndex - 1;
      this.editBanner(this.activeIndex);
      this.projects =  this.localBanners[this.activeIndex].projects
    },
    nextSlide() {
      this.activeIndex =
        this.activeIndex === this.localBanners.length - 1 ? 0 : this.activeIndex + 1;
      this.editBanner(this.activeIndex);
      this.projects =  this.localBanners[this.activeIndex].projects
    },

    isURL(cadena, type) {
      try {
        new URL(cadena);
        return true;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "XCircleIcon",
            variant: "danger",
            text: `${type}: URL is not valid`,
          },
        });
        return false;
      }
    },
    closeToastBottoms() {
      (this.buttoms = {
        text: "",
        link: "",
        background: "#0078C4",
        color: "#FFFFFF",
      }),
        (this.buttomIndex = -1);
      this.isToastButtoms = false;
    },
    showToast(buttom, i) {
      this.isToastButtoms = true;
      this.buttoms = {
        ...buttom,
      };
      this.buttomIndex = i;
    },
    addEditButton() {
      if (this.buttomIndex !== -1) {
        this.bannerForm.buttons[this.buttomIndex] = this.buttoms;
      } else {
        this.bannerForm.buttons.push({
          ...this.buttoms,
        });
      }
      this.saveBanners();
      this.closeToastBottoms();
    },
    deleteButton() {
      this.bannerForm.buttons.splice(this.buttomIndex, 1);
      this.saveBanners();
      this.closeToastBottoms();
    },
    setCaptionClass(position) {
      let newClass;
      switch (position) {
        case "left top":
          newClass = "left-top";
          break;
        case "top center":
          newClass = "top-center";
          break;
        case "right top":
          newClass = "right-top";
          break;
        case "left center":
          newClass = "left-center";
          break;
        case "center center":
          newClass = "center-center";
          break;
        case "right center":
          newClass = "right-center";
          break;
        case "left bottom":
          newClass = "left-bottom";
          break;
        case "bottom center":
          newClass = "bottom-center";
          break;
        case "right bottom":
          newClass = "right-bottom";
          break;
        default:
          newClass = "left-center";
      }

      return newClass;
    },
    selectCustomer(projects) {
      console.log(projects, 'project')
      this.projects = projects;
    },
    async autoFillProjects() {
      this.activeIndex = 0;
      if (this.localBanners.length === 0) {
        this.showBanners = false;
      } else {
        this.showBanners = true
        this.projects =  this.localBanners[this.activeIndex].projects
      //   for (let item of this.localBanners) {
      //     for (let prject of item.projects) {
      //       if (prject === this.wl) {
      //         this.showBanners = true;
      //         this.projects =  this.localBanners[this.activeIndex].projects
      //         console.log(this.projects, 'aqui llegan')
      //       }
      //     }
        
      // }
      }
        

      /*       if (this.showBanners) {
            
            } */
    },
    editBanner(index) {
      this.bannerForm = { ...this.localBanners[index] };
    },
    saveBanners() {

      try {
        this.$set(this.localBanners, this.activeIndex, { ...this.bannerForm });
      } catch (error) {
        console.log(error);
      }
    },
    async putBanners() {
      try {
        if (!this.isURL(this.bannerForm.image, "image")) return;
        if (this.projects === "") {
          this.bannerForm.projects = this.wl;
        }
        // if (!this.isURL(this.bannerForm.link, "link")) return;
        this.loadingData = true;

        const payload = {
          data: this.localBanners,
        };
        let wl = this.wl;
        await this.$store.dispatch("betcoreBanners/betcoreBanners", {
          payload,
          wl,
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckCircleIcon",
            variant: "success",
            text: "Banners update",
          },
        });
        this.loadingData = false;
        this.editBanner(this.activeIndex);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
      }
    },
    deleteBanner() {
      this.$swal({
        title: "Are you sure you want to remove this banner?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.localBanners.splice(this.activeIndex, 1);
          this.activeIndex = 0;
          this.putBanners();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "CheckCircleIcon",
              variant: "success",
              text: "Banners deleted",
            },
          });
        }
      });
    },
    selectPosition(position) {
      this.bannerForm.position = position;
      this.saveBanners();
    },
    BannerPlatform(platform) {
      if (platform === "isAll") {
        this.isAll = true;
        this.isMovil = false;
        this.isDesktop = false;
        return this.localBanners;
      } else if (platform === "isMovil") {
        this.isMovil = true;
        this.isDesktop = false;
        this.isAll = false;
        return this.localBanners.filter((banner) => banner.platform === 2);
      } else if (platform === "isDesktop") {
        this.isDesktop = true;
        this.isMovil = false;
        this.isAll = false;
        return this.localBanners.filter((banner) => banner.platform === 1);
      }
    },
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 4rem;
  height: 4rem;
  background-size: 100%, 100%;
}

.left-top {
  top: 5%;
  left: 10%;
  transform: translate(0, 0);
}

.top-center {
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
}

.right-top {
  top: 5%;
  right: 0%;
  transform: translate(200%, 0);
}

.left-center {
  top: 58%;
  left: 10%;
  transform: translate(0, -50%);
}

.center-center {
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.right-center {
  top: 58%;
  right: 10%;
  transform: translate(200%, -50%);
}

.left-bottom {
  bottom: 10%;
  left: 10%;
  transform: translate(0, 0);
}

.bottom-center {
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.right-bottom {
  bottom: 10%;
  right: 0%;
  transform: translate(200%, 0);
}

@media (max-width: 500px) {
  .left-top {
    top: -5%;
    left: 5%;
    transform: translate(0, 0);
  }

  .top-center {
    top: -5%;
    left: 30%;
    transform: translate(-50%, 0);
  }

  .right-top {
    top: -5%;
    right: 10%;
    transform: translate(60%, 0);
  }

  .left-center {
    top: 40%;
    left: 5%;
    transform: translate(0, -50%);
  }

  .center-center {
    top: 40%;
    left: 30%;
    transform: translate(-50%, -50%);
  }

  .right-center {
    top: 40%;
    right: 6%;
    transform: translate(50%, -50%);
  }

  .left-bottom {
    bottom: 6%;
    left: 5%;
    transform: translate(0, 0);
  }

  .bottom-center {
    bottom: 6%;
    left: 30%;
    transform: translate(-50%, 0);
  }

  .right-bottom {
    bottom: 6%;
    right: 5%;
    transform: translate(60%, 0);
  }
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
